#glass {
  width: 70px;
  height: 90px;
  background: transparent;
  border: solid 3.5px white;
  border-top: none;
  border-radius: 0 0 10px 10px;
  position: absolute;
  top: 8px;
  left: calc(50% - 35px);
  overflow: hidden;
  transform: rotate(-20deg);
  animation: glass 10s infinite;
}

#poignet {
  width: 30px;
  height: 55px;
  background: transparent;
  border: solid 3.5px white;
  position: absolute;
  top: 0px;
  left: calc(50% + 35px - 2px);
  border-radius: 0 16px 16px 0;
  border-left: none;
  transform: rotate(-20deg);
  animation: poignet 10s infinite;
}

#beer {
  width: 59px;
  height: 84px;
  background: transparent;
  position: absolute;
  left: 2px;
  top: 0px;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}
#beer::before {
  content: "";
  width: 250px;
  height: 250px;
  background: #E8B504;
  position: absolute;
  top: 83px;
  left: -190px;
  transform: rotate(40deg);
  animation: beer 10s infinite;
}

#mousse_1 {
  position: absolute;
  top: 0px;
  left: calc(50% - 40px);
  z-index: 4;
}
#mousse_1::before {
  content: "";
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse 10s infinite;
}
#mousse_1::after {
  content: "";
  width: 17px;
  height: 17px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 10px;
  animation: mousse 10s infinite;
}

#mousse_2 {
  position: absolute;
  top: 0px;
  left: calc(50% - 30px);
  z-index: 4;
}
#mousse_2::before {
  content: "";
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse 10s infinite;
}
#mousse_2::after {
  content: "";
  width: 17px;
  height: 17px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 10px;
  animation: mousse 10s infinite;
}

#mousse_3 {
  position: absolute;
  top: 0px;
  left: calc(50% - 10px);
  z-index: 4;
}
#mousse_3::before {
  content: "";
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse 10s infinite;
}
#mousse_3::after {
  content: "";
  width: 17px;
  height: 17px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 15px;
  animation: mousse 10s infinite;
}

#mousse_4 {
  position: absolute;
  top: 0px;
  left: calc(50%);
  z-index: 4;
}
#mousse_4::before {
  content: "";
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse 10s infinite;
}
#mousse_4::after {
  content: "";
  width: 17px;
  height: 17px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 10px;
  animation: mousse 10s infinite;
}

#mousse_5 {
  position: absolute;
  top: 0px;
  left: calc(50% + 20px);
  z-index: 4;
  animation: mousse 10s infinite;
}
#mousse_5::before {
  content: "";
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
}

#mousse_volante {
  position: absolute;
  top: 0px;
  left: calc(50% - 20px);
  z-index: 4;
}
#mousse_volante::before {
  content: "";
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse_volante 10s infinite;
}
#mousse_volante::after {
  content: "";
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 40px;
  animation: mousse_volante 10s infinite;
}

#mousse_interieur {
  position: absolute;
  top: 63px;
  left: calc(50%);
  z-index: 4;
  opacity: 0.9;
}
#mousse_interieur::before {
  content: "";
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse_interieur 10s infinite;
}
#mousse_interieur::after {
  content: "";
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 30px;
  animation: mousse_interieur 5s infinite;
}

#mousse_interieur_2 {
  position: absolute;
  top: 43px;
  left: calc(50% - 10px);
  z-index: 4;
  opacity: 0.9;
}
#mousse_interieur_2::before {
  content: "";
  width: 7px;
  height: 7px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse_interieur 10s infinite;
}
#mousse_interieur_2::after {
  content: "";
  width: 3px;
  height: 3px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 17px;
  animation: mousse_interieur 5s infinite;
}

#mousse_interieur_3 {
  position: absolute;
  top: 53px;
  left: calc(50% - 15px);
  z-index: 4;
  opacity: 0.9;
}
#mousse_interieur_3::before {
  content: "";
  width: 9px;
  height: 9px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse_interieur 10s infinite;
}
#mousse_interieur_3::after {
  content: "";
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 12px;
  animation: mousse_interieur 5s infinite;
}

#mousse_interieur_4 {
  position: absolute;
  top: 49px;
  left: calc(50% + 10px);
  z-index: 4;
  opacity: 0.9;
}
#mousse_interieur_4::before {
  content: "";
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: mousse_interieur 10s infinite;
}
#mousse_interieur_4::after {
  content: "";
  width: 5.5px;
  height: 5.5px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 28px;
  left: 2px;
  animation: mousse_interieur 5s infinite;
}

/* ANIMATIONS */
@keyframes beer {
  0% {
    transform: translateY(-5);
  }
  17% {
    transform: translateY(-82px);
  }
  100% {
    transform: translateY(-82px);
  }
}
@keyframes glass {
  0% {
    transform: rotate(-20deg);
  }
  17% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes poignet {
  0% {
    transform: rotate(-20deg);
  }
  17% {
    transform: rotate(0deg) translateX(5px) translateY(20px);
  }
  100% {
    transform: rotate(0deg) translateX(5px) translateY(20px);
  }
}
@keyframes mousse {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
}
@keyframes mousse_volante {
  0% {
    transform: scale(0) translateY(0px);
  }
  10% {
    transform: scale(0) translateY(0px);
  }
  20% {
    transform: scale(1) translateY(-20px);
  }
  40% {
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(-80px);
    opacity: 0;
  }
}
@keyframes mousse_interieur {
  0% {
    transform: scale(0) translateY(0px);
  }
  20% {
    transform: scale(0) translateY(0px);
  }
  40% {
    transform: scale(1) translateY(-60px);
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(-60px);
    opacity: 0;
  }
}

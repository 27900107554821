@keyframes pulse {
	0% {
		transform: scale(0.95);
		/*box-shadow: 0 0 0 0 rgba(68, 220, 68, 0.5);*/
	}

	70% {
		transform: scale(1.2);
		/*box-shadow: 0 0 0 10px rgba(68, 220, 68, 0.5);*/
	}

	100% {
		transform: scale(0.95);
		/*box-shadow: 0 0 0 0 rgba(68, 220, 68, 0.5);*/
	}
}
@keyframes pulse-wrong {
	0% {
		transform: scale(0.95);
		/*box-shadow: 0 0 0 0 rgba(255, 68, 68, 0.5);*/
	}

	70% {
		transform: scale(1.2);
		/*box-shadow: 0 0 0 10px rgba(255, 68, 68, 0.5);*/
	}

	100% {
		transform: scale(0.95);
		/*box-shadow: 0 0 0 0 rgba(255, 68, 68, 0.5);*/
	}
}
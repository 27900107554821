.moes-hide {
  opacity: 0;
}

.moes-fade-show {
  opacity: 1;
  transition: all 2000ms;
}

.moes-show {
  opacity: 1;
}

.moes-fade-hide {
  opacity: 0;
  transition: all 2000ms;
}
